<template>
  <div class="wap-index">
    <div class="top-info flex-center-between">
      <div class="logo flex-center">
        <img :src="logo || logoUrl" alt="" />
        <span class="app-name">{{ appName }}</span>
      </div>
      <div class="flex-center right">
        <img :src="langIcon" alt="" @click="toPath('wapLang')" />
        <i class="el-icon-arrow-down arrow"></i>
        <i class="el-icon-bell" @click="toPath('wapNotice')"></i>
      </div>
    </div>

    <div class="index-main">
      <div class="top-search flex-center" @click="toSearch">
        <i class="el-icon-search"></i>
        {{ $t('zhao-huo-yuan-shang-pin-gong-ying-shang-cai-gou') }}
      </div>

      <div class="banner">
        <Swipe
          ref="swipe"
          :autoplay="3000"
          :loop="true"
          :show-indicators="false"
        >
          <SwipeItem v-for="(item, i) in bannerList" :key="i">
            <img :src="item.Img" alt="" />
          </SwipeItem>
        </Swipe>
      </div>

      <div class="category-list">
        <Swipe
          ref="swipe"
          :autoplay="3000"
          :loop="false"
          :show-indicators="false"
          :width="98"
          :height="102"
        >
          <SwipeItem
            v-for="(item, i) in categoryList"
            :key="i"
            @click="toClassify(item)"
          >
            <div class="category-box flex-column-start">
              <div class="img">
                <img :src="item.Image || categoryUrl" alt="" />
              </div>
              <div class="label">{{ item.title }}</div>
            </div>
          </SwipeItem>
        </Swipe>
      </div>

      <div class="page-title">{{ $t('mei-ri-shang-xin-0') }}</div>

      <div class="newest-list">
        <Swipe
          ref="swipe"
          :autoplay="3000"
          :loop="false"
          :show-indicators="false"
          :width="135"
          :height="146"
        >
          <SwipeItem v-for="(item, i) in newestList" :key="i">
            <div
              class="banner-goods flex-column-start"
              @click="toGoodsDetail(item)"
            >
              <div class="img flex-center">
                <img :src="item.MainImage" alt="" />
              </div>
              <div class="price">${{ item.SalePrice }}</div>
            </div>
          </SwipeItem>
        </Swipe>
      </div>

      <div class="page-title">{{ $t('tui-jian-chan-pin-0') }}</div>

      <div class="recommend-list">
        <Swipe ref="swipe" :loop="false" :show-indicators="false">
          <SwipeItem v-for="(item, i) in recommendList" :key="i">
            <div class="recommend-box">
              <goodsList :list="item"></goodsList>
            </div>
          </SwipeItem>
        </Swipe>
      </div>

      <div class="page-title">{{ $t('tui-jian-dian-pu-0') }}</div>
      <div class="shop-list" v-for="(item, i) in shopList" :key="i">
        <div class="shop">
          <div class="flex-center top">
            <div class="img">
              <img :src="item.Logo" />
            </div>
            <div class="flex-1 flex-center-between">
              <div class="flex-1">
                <div class="name">{{ item.Name }}</div>
                <div class="flex-center-between">
                  <div>
                    <div class="info">
                      {{ $t('guan-zhu-0') }}
                      0
                    </div>
                    <div class="info">
                      {{ $t('shang-pin') }}
                      0
                    </div>
                  </div>
                </div>
              </div>
              <el-button @click="toShop(item)"
                >{{ $t('fang-wen-shang-dian')
                }}<i class="el-icon-arrow-right"></i
              ></el-button>
            </div>
          </div>

          <div class="flex-center-between bottom">
            <div class="info">
              {{ $t('ping-fen') }}
              {{ item.GoodsScore }}
            </div>
            <div class="info">
              {{ $t('xiao-liang') }}
              {{ item.TotalDeal }}
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-banner">
        <div class="flex-column-start-center info">
          <div class="title">
            {{ $t('kua-jing-dian-shang-pi-fa-shang-chang') }}
          </div>
          <div class="desc">{{ $t('pin-pai-te-mai-zhe-kou-bu-ting') }}</div>
          <div class="flex btn-box">
            <div class="btn flex-center">
              <img :src="androidUrl" alt="" />
              {{ $t('an-zhuo-xia-zai') }}
            </div>
            <div class="btn flex-center">
              <img :src="iosUrl" alt="" />
              {{ $t('ping-guo-xia-zai') }}
            </div>
          </div>
        </div>
      </div>

      <div class="page-title">{{ $t('re-xiao-chan-pin-0') }}</div>
      <goodsList :list="hotGoodsList"></goodsList>
    </div>
    <footerBar></footerBar>

    <div class="to-top flex-center" v-show="showTop" @click="toTop">
      <i class="el-icon-arrow-up"></i>
    </div>
  </div>
</template>
<script>
import { getBannerList } from '@/api/index'
import { shopProductList, getShopList } from '@/api/shop'
import { Button, Swipe, SwipeItem, Popup, Icon } from 'vant'
import goodsList from '@/components/goodsList/wapUserList'
import footerBar from '@/components/footer/wapFooter'
export default {
  name: 'about',
  components: {
    Button,
    Popup,
    Icon,
    Swipe,
    SwipeItem,
    goodsList,
    footerBar
  },
  data() {
    return {
      activeIndex: 0,
      androidUrl: require('@/assets/imgs/icon-android.png'),
      iosUrl: require('@/assets/imgs/icon-ios.png'),
      logoUrl: require('@/assets/imgs/logo.png'),
      categoryUrl: require('@/assets/imgs/category1.png'),
      bannerList: [],
      newestList: [],
      hotGoodsList: [],
      shopList: [],
      recommendList: [],
      showTop: false
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName
    },
    logo() {
      return this.$store.state.logo
    },
    lang() {
      return this.$store.state.lang
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    categoryList() {
      return this.$store.state.categoryList
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.pageScorll)
  },
  mounted() {
    this.init()
    document.body.addEventListener('scroll', this.pageScorll)
  },
  methods: {
    pageScorll(e) {
      let top = e.target.scrollTop
      if (top > 300) {
        this.showTop = true
      } else {
        this.showTop = false
      }
    },
    toTop() {
      document.body.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    init() {
      this.$store.dispatch('initCategory', this.lang)
      getBannerList().then((res) => {
        this.bannerList = res.data.Items
      })

      // 新品首发
      shopProductList({
        current: 1,
        pageSize: 20
      }).then((res) => {
        this.newestList = res.data.Items
      })

      // 推荐产品
      shopProductList({
        current: 1,
        pageSize: 24,
        Guess: 1
      }).then((res) => {
        let list = res.data.Items
        let param = []
        list.forEach((v, i) => {
          if (i % 4 == 0) {
            param = []
            param.push(v)
            this.recommendList.push(param)
          } else {
            let index = parseInt(i / 4)
            this.recommendList[index].push(v)
          }
        })
      })

      // 热销产品
      shopProductList({
        current: 1,
        pageSize: 10,
        Sort: 'sales'
      }).then((res) => {
        this.hotGoodsList = res.data.Items
      })

      // 店铺列表
      getShopList({}).then((res) => {
        this.shopList = res.data.Items
      })
    },
    toGoodsList(data) {
      this.$router.push({
        name: 'wapCommodity',
        query: {
          id: data.ID
        }
      })
    },
    toGoodsDetail(data) {
      this.$router.push({
        name: 'wapUserProductDetail',
        query: {
          id: data.ID
        }
      })
    },
    toShop(data) {
      this.$router.push({
        name: 'wapShopInfo',
        query: {
          id: data.ID
        }
      })
    },
    toClassify(data) {
      this.$router.push({
        name: 'wapClassify',
        query: {
          id: data.ID,
          title: data.title
        }
      })
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    toSearch() {
      this.$router.push({
        name: 'wapSearch',
        query: {
          type: 3
        }
      })
    }
  }
}
</script>
<style lang="less">
</style>
